<!--
 * @Description: 服务类型管理
 * @Author: ChenXueLin
 * @Date: 2021-10-20 14:58:17
 * @LastEditTime: 2022-07-15 15:50:35
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="firstServerCode">
              <e6-vr-select
                v-model="searchForm.firstServerCode"
                :data="firstTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="一级服务类型"
                title="一级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondServerCode">
              <e6-vr-select
                v-model="searchForm.secondServerCode"
                :data="secondTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="二级服务类型"
                title="二级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="frameworkId">
              <!-- 部门下拉框 -->
              <e6-select
                style="width:200px"
                v-model="searchForm.frameworkId"
                :dataList="treeData"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="false"
                ref="t_select"
                placeholder="部门"
              ></e6-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus('enable')"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus('disable')"
          ></i>
          <i @click="handleAdd(1)" title="添加一级服务类型">
            添加一级服务类型
          </i>
          <i @click="handleAdd(2)" title="添加二级服务类型">
            添加二级服务类型
          </i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加一级服务类型 start -->
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="addDioalog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="editCustomerDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addFirstForm"
        label-width="120px"
        :model="addFirstForm"
        v-if="dialogType == 1 || dialogType == 3"
        :rules="addFirstFormRules"
      >
        <el-form-item label="一级服务类型名称" prop="firstServer">
          <el-input
            v-model.trim="addFirstForm.firstServer"
            placeholder="一级服务类型名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="departmentId">
          <!-- 部门下拉框 -->
          <e6-select
            v-model="addFirstForm.departmentId"
            :dataList="treeData"
            :filterable="true"
            :slotTemplate="'tree'"
            :multiple="false"
            :issingleleaf="false"
            ref="t_select"
          ></e6-select>
        </el-form-item>
      </el-form>
      <el-form
        ref="addSecondForm"
        label-width="120px"
        :model="addSecondForm"
        :rules="addSecondFormRules"
        v-else
      >
        <el-form-item label="二级服务类型名称" prop="secondServer">
          <el-input
            v-model.trim="addSecondForm.secondServer"
            placeholder="二级服务类型名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属一级服务类型" prop="firstServerCode">
          <e6-vr-select
            v-model="addSecondForm.firstServerCode"
            :data="firstTypeList"
            placeholder="所属一级服务类型"
            title="所属一级服务类型"
            clearable
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getCustomerTypeList,
  batchCustomerType,
  getFirstType,
  addCustomerType,
  updateCustomerType,
  getSecondType,
  getFrameworkTree
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "serviceTypeManage",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      total: 0,
      levelOne: true, //是否是添加一级服务类型弹框
      searchForm: {
        firstServerCode: "", //一级服务类型
        secondServerCode: "", //二级服务类型
        createdBy: "", //创建人
        createTime: [],
        beginTimeTimestamp: "", //查询开始时间时间戳类型
        endTimeTimestamp: "", //查询结束时间时间戳类型
        pageIndx: 1,
        pageSize: 20,
        frameworkId: []
      },
      firstTypeList: [], //一级服务类型下拉框数据
      secondTypeList: [], //二级服务类型下拉框数据
      selColumnId: [],
      columnData: [
        {
          fieldName: "firstServer",
          display: true,
          fieldLabel: "一级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondServer",
          display: true,
          fieldLabel: "二级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "enableStatus",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "frameworkName",
          display: true,
          fieldLabel: "部门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      /***新增****/
      treeData: [],
      addFirstForm: {
        firstServer: "",
        departmentId: [],
        frameworkId: ""
      }, //一级服务类型
      addFirstFormRules: {
        firstServer: [
          {
            required: true,
            message: "请输入一级服务类型名称",
            trigger: ["blur", "change"]
          }
        ],
        departmentId: [
          {
            required: true,
            message: "请选择部门",
            trigger: ["blur", "change"]
          }
        ]
      },
      addSecondForm: {
        firstServerCode: "", //一级服务类型code
        secondServer: "" //二级服务类型
      }, //二级服务类型
      addSecondFormRules: {
        firstServerCode: [
          {
            required: true,
            message: "请选择一级服务类型名称",
            trigger: ["blur", "change"]
          }
        ],
        secondServer: [
          {
            required: true,
            message: "请填写二级服务类型名称",
            trigger: ["blur", "change"]
          }
        ]
      },
      editCustomerDialogLoading: false,
      dialogType: 1,
      addDioalog: false,
      dialogTitle: "添加一级服务类型",
      refTable: "tableList",
      queryListAPI: getCustomerTypeList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    },
    // 监听一级分类选择更改
    "searchForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType();
        } else {
          // 当一级分类选择为空时重置数据
          this.secondTypeList = [];
          this.searchForm.firstServerCode = "";
          this.searchForm.secondServerCode = "";
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // // 初始化数据
    async initData() {
      try {
        let promiseList = [getFirstType({ whether: 0 }), getFrameworkTree()]; //获取部门结构];
        let [firstRes, frameworkRes] = await Promise.all(promiseList);
        this.firstTypeList = this.getFreezeResponse(firstRes, {
          path: "data"
        });
        this.handleTreeData(frameworkRes.data.children);
      } catch (error) {
        printError(error);
      }
    },
    //处理树形结构
    handleTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].name;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //获取一级服务类型
    async getFirstType() {
      try {
        let res = await getFirstType({ whether: 0 });
        this.firstTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取二级服务类型
    async getSecondType() {
      try {
        let res = await getSecondType({
          firstServerCode: this.searchForm.firstServerCode
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.typeId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //处理启用禁用
    changeStatus(status) {
      if (this.selColumnId.length) {
        let tipTitle =
          status == "enable"
            ? "是否确认启用勾选的数据？"
            : "是否确认禁用勾选的数据？";
        let title = status == "enable" ? "启用问题" : "禁用问题";
        this.$confirm(tipTitle, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (status == "enable") {
              this.batchQuestion(status);
            } else {
              this.batchQuestion(status);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message.warning("未勾选数据");
      }
    },
    //批量启用请求
    async batchQuestion(type) {
      try {
        let res = await batchCustomerType({ ids: this.selColumnId, type });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.selColumnId = [];
          this.initData();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogTitle = row.secondServerCode
          ? "编辑二级服务类型"
          : "编辑一级服务类型";
        this.dialogType = row.secondServerCode ? 4 : 3;
        if (row.secondServerCode) {
          this.addSecondForm = _.cloneDeep(row);
          this.addFirstForm.departmentId = [this.addFirstForm.frameworkId];
        } else {
          this.addFirstForm = _.cloneDeep(row);
          this.addFirstForm.departmentId = [this.addFirstForm.frameworkId];
        }
        this.addDioalog = true;
      }
    },
    /**********新增*********/
    //创建服务类型
    handleAdd(dialogType) {
      this.dialogType = dialogType;
      this.addDioalog = true;
      this.dialogTitle =
        dialogType == 1 ? "添加一级服务类型" : "添加二级服务类型";
      this.addDialog = true;
    },
    //确定新增服务类型
    handleConfirm() {
      if (this.dialogType == 1 || this.dialogType == 3) {
        this.$refs.addFirstForm.validate(valid => {
          if (valid) {
            if (this.dialogType == 1) {
              this.addFirstForm.frameworkId = this.addFirstForm.departmentId
                .length
                ? this.addFirstForm.departmentId.join()
                : "";
              this.addCustomerType(this.addFirstForm);
            } else {
              this.addFirstForm.frameworkId = this.addFirstForm.departmentId
                .length
                ? this.addFirstForm.departmentId.join()
                : "";
              this.updateCustomerType(this.addFirstForm);
            }
          }
        });
      } else {
        this.$refs.addSecondForm.validate(valid => {
          if (valid) {
            let firstServer = this.firstTypeList.filter(item => {
              return item.codeId == this.addSecondForm.firstServerCode;
            });
            if (this.dialogType == 2) {
              this.addCustomerType({
                ...this.addSecondForm,
                firstServer: firstServer[0].codeName
              });
            } else {
              this.updateCustomerType({
                ...this.addSecondForm,
                firstServer: firstServer[0].codeName
              });
            }
          }
        });
      }
    },
    //新增请求
    async addCustomerType(data) {
      try {
        let res = await addCustomerType(data);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.initData();
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******编辑******/
    //编辑请求
    async updateCustomerType(data) {
      try {
        let res = await updateCustomerType(data);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.initData();
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addFirstForm = {
        firstServer: ""
      };
      this.addSecondForm = {
        firstServer: "",
        firstServerCode: "", //一级服务类型code
        secondServer: "" //二级服务类型
      };
      if (this.dialogType == 1 || this.dialogType == 3) {
        this.$refs.addFirstForm.resetFields();
      } else {
        this.$refs.addSecondForm.resetFields();
      }

      this.addDioalog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-dialog__body {
    padding: 20px 20px 0;
    .el-form {
      .el-input {
        width: 220px;
      }
    }
  }
}
/deep/.search-item--1 {
  .sub-selected-value {
    width: 200px !important;
  }
}
</style>
